import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Block = styled.div`
  background-color: ${color.COLOR2};

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  & div:first-of-type {
    flex: 0 1 350px;
  }
  & p {
    flex: 1 1;
    padding: 5em;
    margin-bottom: 0;
  }
  & a {
    color: white;
  }
  @media(max-width: 930px) {
    & p {
      padding: 2em;
    }
    & div:first-of-type {
      max-width: 100%;
    }
    flex-direction: column;
  }
`

const CallToAction = styled(Block)`
  margin-top: 4em;
  & div:first-of-type {
    height: 200px;
  }
  & p {
    padding: 2em;
  }
  & div:nth-of-type(2) {
    flex: 0 1 100px;
    & img {
      width: 50px;
      height: 50px;
      margin: auto;
    }
  }
  -webkit-transition: background-color 0.25s linear;
  -ms-transition: background-color 0.25s linear;
  transition: background-color 0.25s linear;

  &:hover {
    background-color: ${color.COLOR2INTENSE};
    

    & div:nth-of-type(2) {
      transition: transform 0.5s ease-in-out;
      transform: translateX(20px);
    }
  }
  @media(max-width: 930px) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`

const CustomLink = styled.a`
  opacity: 1 !important;
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
  }
`

const CustomLinkDisabled = styled.span`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  cursor: default;
`

const IndexPage = () => {
  const intl = useIntl()
  return (
    <>
    
    <Layout hideLogo={true}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={[`gatsby`, `application`, `react`]}
      />


      <div css={css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        display: flex;
      `}>
        <video
        autoPlay
        muted
        loop
        css={css`
          position: absolute; 
          right: 0; 
          bottom: 0;
          min-width: 100%; 
          min-height: 100%;
          width: auto; 
          height: auto; 
          z-index: -100;
          background-size: cover;
          overflow: hidden;
          `}
        >
          <source src="/bg-video.mp4" type="video/mp4" />
        </video>
        <div css={css`
          position: absolute; 
          right: 0; 
          bottom: 0;
          min-width: 100%; 
          min-height: 100%;
          width: auto; 
          height: auto; 
          z-index: -100;
          background-size: cover;
          overflow: hidden;
          /* background-color: rgba(0,0,0,0.5); */
          `}>

        </div>
      </div>


      <div css={css`
        height: 100vh;
        z-index: 99;
        display: flex;
      `}>
        <StaticImage
          src="../images/orpheus-logo-white.png"
          width={250}
          quality={95}
          placeholder={"tracedSVG"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Orpheus Music Logo"
          css={css`margin: auto; width: 250px;`}
        />
      </div>

      {/* <Link to="/preistraeger-2021" css={css`
        color: ${color.WHITE};
        text-decoration: none;`}>
        <CallToAction>
          <StaticImage
            src="../images/kammermusik-2.jpg"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Musikerin Geige"
          />
          <p>
            <h1><FormattedMessage id="preistrager" /> 2021</h1>
            <FormattedMessage id="gratulation" />
          </p>
          <StaticImage
            src="../images/arrow-right.png"
            width={50}
            height={50}
            quality={95}
            placeholder={"tracedSVG"}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Pfeil"
          />
        </CallToAction>
      </Link> */}
        
        {/* <CallToAction>
          <p style={{paddingTop: '2em'}}>
            <h1 style={{marginBottom: '.5em'}}><FormattedMessage id="wettbewerb" /> 2023</h1>
            <FormattedMessage id="wettbewerb-2023-info" />
          </p>
        </CallToAction> */}

      <Link to="/wettbewerb-2024" css={css`
        color: ${color.WHITE};
        text-decoration: none;`}>
        <CallToAction>
          <StaticImage
            src="../images/kammermusik-2.jpg"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Musikerin Geige"
          />
          <p style={{paddingTop: '2.3em'}}>
            <>
              <h1><FormattedMessage id="wettbewerb" /> 2024</h1>
              <FormattedMessage
            id="wettbewerb-2024-text"
            values={{
              a1: msg => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/download/${intl.formatMessage({
                    id: "reglement-url",
                  })}.pdf`}
                  css={css`
                    color: white;
                    transition: opacity 0.25s;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                >
                  {msg}
                </a>
              ),
              a2: msg => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.muvac.com/de/Vacancies/ensemble"
                  css={css`
                    color: white;
                    transition: opacity 0.25s;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                >
                  {msg}
                </a>
              ),
              b: msg => <b>{msg}</b>,
              u: msg => <u>{msg}</u>,
              br: (
                <>
                  <br />
                </>
              ),
            }}
          />
            </>
            {/* <FormattedMessage id="anmeldung-offen" /> */}
          </p>
          <StaticImage
            src="../images/arrow-right.png"
            width={50}
            height={50}
            quality={95}
            placeholder={"tracedSVG"}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Pfeil"
          />
        </CallToAction>
      </Link>

      <h1 css={css`margin-top: 2em;`}>
        <FormattedMessage id="uber-den-wettbewerb" />
      </h1>
      <Block>
        <StaticImage
          src="../images/musikerin-geige.jpg"
          width={500}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Musikerin Geige"
        />
        <p>
          <FormattedMessage id="uber-den-wettbewerb-text"
          values={{
            a1: msg => (
              <a target="_blank" rel="noopener noreferrer"  href="https://www.swisschambermusicfestival.ch/">
                {msg}
              </a>
            ),
            a2: msg => (
              <a target="_blank" rel="noopener noreferrer" href="https://www.musikdorf.ch/">
                {msg}
              </a>
            ),
            a3: msg => (
              <a target="_blank" rel="noopener noreferrer" href="https://www.zunftkonzerte.ch">
                {msg}
              </a>
            ),
            a4: msg => (
              <a target="_blank" rel="noopener noreferrer" href="https://www.pianotriofest.ch">
                {msg}
              </a>
            ),
            a5: msg => (
              <a target="_blank" rel="noopener noreferrer" href="https://zimmermannhaus.ch/K_RUCKSCHAU">
                {msg}
              </a>
            ),
            a6: msg => (
              <a target="_blank" rel="noopener noreferrer" href="https://www.gml-kreuzlingen.ch">
                {msg}
              </a>
            ),
            quote: <span>"</span>
          }} />
        </p>
      </Block>

      <h1 css={css`margin-top: 2em;`}>
        <FormattedMessage id="ensembles" />
      </h1>
      <Block>
        <p>
          <FormattedMessage id="ensembles-text" />
        </p>
        <StaticImage
          src="../images/keby-art-ensemble.jpg"
          width={500}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Ensemble"
        />
      </Block>

      {
        ensembles.slice(0,15).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }

      <h1 css={css`margin-top: 2em;`}>
        <FormattedMessage id="komponierende" />
      </h1>
      <Block>
        <p>
          <FormattedMessage id="komponierende-text" />
        </p>
        <StaticImage
          src="../images/notenschrift.png"
          width={500}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Ensemble"
        />
      </Block>

      {
        composers.map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"composer"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
    </Layout>
    </>
  )
}

export default IndexPage